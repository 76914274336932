<template>
  <div class="login-pass">
    <a-form :model="formState" @finish="handleFinish" @finishFailed="handleFinishFailed">
      <a-form-item>
        <a-input v-model:value="formState.username" placeholder="请输入账号" size="large">
          <template #prefix>
            <img :src="iconuser" style="width: 20px;height: 20px;" alt="" srcset="">
          </template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input v-model:value="formState.password" type="password" placeholder="请输入密码" size="large">
          <template #prefix>
            <img :src="iconsafe" style="width: 20px;height: 20px;" alt="" srcset="">
          </template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button size="large" class="save-btn" type="primary" html-type="submit"
          :disabled="formState.username === '' || formState.password === ''">立即登录</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script setup>
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { reactive } from 'vue';
import { loginPass } from '@/common/api'
import { setLocalStorage } from '@/common/storage'
import iconphone from '@/assets/images/login/icon-phone.png'
import iconuser from '@/assets/images/login/icon-user.png'
import iconsafe from '@/assets/images/login/icon-s.png'
const formState = reactive({
  username: '',
  password: '',
});

const handleFinish = values => {
  console.log(values, formState);
  loginPass({
    data: {
      ...formState
    },
    success: res => {
      setLocalStorage('accessToken', res.access_token, res.expires_in)
      window.location.href = location.origin + '/oadmin/'
    }
  })
};

const handleFinishFailed = errors => {
  console.log(errors);
};


</script>
<style lang="less" scoped>
.login-pass {
  width: 326px;
  padding-top: 30px;

  :deep(.ant-input-affix-wrapper) {
    background: #FAFBFF;
    border-radius: 25px;
    border: none;
    height: 46px;
    line-height: 46px;

    input {
      background: transparent;
    }
  }

  .save-btn {
    background: #1990FF;
    border: none;
    width: 100%;
    color: #fff;
    border-radius: 26px;
    height: 46px;
  }
}
</style>